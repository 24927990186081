import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import { GET_INVOICES, GET_INVOICE_DETAIL } from "./actionTypes";
import {
  getInvoicesSuccess,
  getInvoicesFail,
  getInvoiceDetailSuccess,
  getInvoiceDetailFail,
} from "./actions";

//Include Both Helper File with needed methods
import { getInvoices, getInvoiceDetail } from "helpers/fakebackend_helper";

function* fetchInvoices() {
  try {
    const response = yield call(getInvoices);
    yield put(getInvoicesSuccess(response));
  } catch (error) {
    yield put(getInvoicesFail(error));
  }
}

function* fetchInvoiceDetail({ invoiceId }) {
  try {
    const response = yield call(getInvoiceDetail, invoiceId);
    yield put(getInvoiceDetailSuccess(response));
  } catch (error) {
    yield put(getInvoiceDetailFail(error));
  }
}

//deleting all invoice
function* onDeleteAllInvoice() {
  try {
    const response = yield call(deleteAllInvoiceApi);
    yield put(deleteAllInvoiceSuccess());
    showToast("All Invoice Deleted Successfully!", "Deleted !");
  } catch (error) {
    yield put(deleteAllInvoiceFail(error));
    showToast(`Invoice failed to Delete ${error}`, "Error Deleting", "error");
  }
}

function* onDeleteInvoice({ payload: { customerId, invoiceId } }) {
  try {
    const response = yield call(deleteInvoiceApi, customerId, invoiceId);
    yield put(deleteInvoiceSuccess(invoiceId));
    showToast("Invoice Deleted Successfully!", "Deleted !");
  } catch (error) {
    yield put(deleteInvoiceFail(error));
    showToast(`Invoice failed to Delete ${error}`, "Error Deleting", "error");
  }
}

function* invoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices);
  yield takeEvery(GET_INVOICE_DETAIL, fetchInvoiceDetail);
}

export default invoiceSaga;
