//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_SIGNIN_JWT_LOGIN = "/v1/login/basic";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";
export const POST_RESET_PASSWORD = "/reset-password";
export const POST_RESET_PASSWORD_VERIFY =
  "/v1/signup/reset-password/verify?token";
export const POST_JWT_PASSWORD_FORGET = "/v1/signup/reset-password";
export const SIGNUP_VERIFY = "/v1/signup/verify?token";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

export const SIGNUP = "/v1/signup/basic";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//PRODUCT LIST
export const ADD_PRODUCT_IN_LIST = "/v1/products";
export const GET_ONE_PRODUCT = "/v1/products/id/";
export const GET_ALL_PRODUCTS_IN_LIST = "/v1/products/submitted/all";
export const DELETE_PRODUCT_IN_LIST = "/v1/products/id/";
export const UPDATE_PRODUCT_IN_LIST = "v1/products/id/";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/v1/orders";
export const ADD_NEW_ORDER = "/v1/orders";
export const UPDATE_ORDER = "/v1/orders/";
export const DELETE_ORDER = "/v1/orders/";
export const DELETE_ALL_ORDER = "/v1/orders/delete/all";
//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/v1/customer/importcustomers";
export const ADD_NEW_CUSTOMER = "/v1/customer/add";
export const UPDATE_CUSTOMER = "/v1/customer/update/";
export const DELETE_CUSTOMER = "/v1/customer/delete/";
export const DELETE_ALL_CUSTOMERS = "/v1/customer/deleteall";
export const IMPORT_CUSTOMERS = "/v1/customer/importcustomers";

//COLLECTIONS (SAME AS CATEGORIES)
export const GET_COLLECTIONS = "/v1/categories";
export const ADD_COLLECTION = "/v1/categories";
export const UPDATE_COLLECTION = "/v1/categories/";
export const DELETE_COLLECTION = "/v1/categories/";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "v1/orders/get/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";
export const GET_ORDERS_COUNT_DATA = "/v1/orders/get/totalorders";
export const GET_REVENUE_TOTAL_DATA = "/v1/orders/get/totalrevenue";
export const GET_ORDERS_AVERAGE_PRICE = "/v1/orders/get/averageprice";
export const TOP_SELLING_DATA = "/top-selling-data";
export const GET_EARNING_DATA = "/earning-charts-data";
export const GET_PRODUCT_COMMENTS = "/comments-product";
export const ON_LIKNE_COMMENT = "/comments-product-action";
export const ON_ADD_REPLY = "/comments-product-add-reply";
export const ON_ADD_COMMENT = "/comments-product-add-comment";

// Inventory
export const GET_INVENTORY = "/v1/products/getInventory";
export const UPDATE_INVENTORY = "/v1/products/inventory/product/";

//customer details
//orders
export const GET_CUSTOMER_ORDER = "/v1/orders/customer/";
//notes
export const GET_ALL_NOTES = "v1/customernotes/all/";
export const ON_ADD_NOTES = "v1/customernotes/add";
export const DELETE_ALL_NOTES = "v1/customernotes/deleteall/";
export const UPDATE_NOTE = "v1/customernotes";
export const DELETE_NOTE = "v1/customernotes";
//invoice
export const CUSTOMER_INVOICE = "v1/orders/get/invoices";
export const DELETE_ALL_INVOICE = "v1/orders/delete/customerinvoices";
export const DELETE_INVOICE = "v1/orders/delete/customer";
//task
export const ADD_TASK = "v1/customer";

//domain
export const DOMAIN_SUGGESTION = "v1/godaddy/suggest-domains?keyword";
export const DOMAIN_AVAILABILITY = "v1/godaddy/check-domain?domain=";
export const BUY_DOMAIN = "v1/godaddy/buy-domain?domain=";
