import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

/*importing actions for getting and updating data */
import { getInventory as onGetInventory, updateInventory } from "store/actions";
import { map } from "lodash";

/*Declaring map */
// const inventoryState = new Map();

const DropdownValue = {
  TrackQuantity: 0,
  StopTracking: 1,
};

const EcommerceInventory = () => {
  const dispatch = useDispatch();

  const { Data } = useSelector(state => ({
    Data: state.ecommerce.inventory,
  }));

  /*state for setting current index */
  const [selectedIndex, setSelectedIndex] = useState("");
  /*state for setting in_stock and Out_of_stock */
  const [stockValue, setStockValue] = useState(null);
  /*state for product._id */
  const [idValue, setIdValue] = useState(null);
  /*setting SKU input value */
  const [skuValue, setSkuValue] = useState(null);

  /*map for Tracking stock option value i.e Start Tracking or stop Tracking */
  const [inventoryState, setInventoryState] = useState(new Map());

  const { SearchBar } = Search;

  var node = useRef();

  /*Updating Map values */
  // const updateMap = (key, value) => {
  // setInventoryState(new Map(inventoryState.set(key, value)));
  const clear = () => {
    setInventoryState(() => new Map());
  };
  const updateMap = (key, value) => {
    clear();
    setInventoryState(prev => {
      // console.log(clear);
      // prev.clear();
      return new Map(prev).set(key, value);
    });
    // console.log(inventoryState.get(key));

    // setInventoryState(prev => new Map([...prev, [key, value]]));
    // setInventoryState(new Map(inventoryState.set(key, value)));

    console.log(key, value);
  };
  // };

  const InventoryColumns = [
    {
      dataField: "name",
      text: "Product Name",
      sort: true,
    },
    {
      dataField: "inventory.inventoryQuantity",
      text: "Stock Keeping Unit",
      sort: true,
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return { width: "15%", textAlign: "center" };
      },
    },
    {
      dataField: "inventory.status",
      text: "Inventory",
      headerAlign: "center",
      headerStyle: (colum, colIndex) => {
        return { width: "25%", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex) => {
        // console.log(inventoryState.get(row._id));
        return (
          <div>
            {console.log(row._id, inventoryState.get(row._id))}
            {inventoryState.get(row._id) ===
            DropdownValue.TrackQuantity.toString() ? (
              <Form onSubmit={e => e.preventDefault()}>
                <Input
                  defaultValue={row.inventory.inventoryQuantity}
                  type="number"
                  onClick={e => {
                    setStockValue(null);
                    setSkuValue(null);
                  }}
                  onBlur={e => {
                    setSkuValue(`${e.target.value}`);
                    setIdValue(row._id);
                  }}
                />
              </Form>
            ) : (
              <Form onSubmit={e => e.preventDefault()}>
                <FormGroup>
                  <Input
                    id="exampleSelect"
                    defaultValue={row.inventory.status}
                    name="select"
                    type="select"
                    onClick={e => {
                      setStockValue(null);
                      setSkuValue(null);
                    }}
                    onChange={e => {
                      setSelectedIndex(rowIndex);
                      setStockValue(e.target.value);
                      setIdValue(row._id);
                    }}
                  >
                    <option value="in_stock">In Stock</option>
                    <option value="out_of_stock">Out of Stock</option>
                  </Input>
                </FormGroup>
              </Form>
            )}
          </div>
        );
      },
      formatExtraData: {
        selectedIndex,
        setStockValue,
        setIdValue,
        inventoryState,
      },
    },
    {
      dataField: "view",
      text: "",
      isDummyField: true,
      headerStyle: (colum, colIndex) => {
        return { width: "7%", textAlign: "center" };
      },
      formatter: (cell, row, rowIndex, extra) => {
        return (
          <UncontrolledDropdown>
            <DropdownToggle
              style={{
                width: "100%",
                border: "none",
                padding: "none",
              }}
              color="white"
              caret
            >
              <i id="hellips" style={{ fontSize: "25px", fontWeight: "600" }}>
                &hellip;
              </i>
            </DropdownToggle>
            <DropdownMenu
              style={{ width: "100%" }}
              onClick={e => {
                setSelectedIndex(rowIndex);
                updateMap(row._id, e.target.value);
                // console.log(row);
                // console.log(row._id, e.target.value);
                // console.log(typeof DropdownValue.TrackQuantity);
                // inventoryState.set(row._id, e.target.value);
              }}
            >
              {/* {console.log(typeof inventoryState.get(row._id))} */}
              {inventoryState.get(row._id) ===
              DropdownValue.TrackQuantity.toString() ? (
                <DropdownItem value={DropdownValue.StopTracking}>
                  Stop Tracking
                </DropdownItem>
              ) : (
                <DropdownItem value={DropdownValue.TrackQuantity}>
                  Track Quantity
                </DropdownItem>
              )}
              <DropdownItem value="Edit Product Info">
                Edit Product Info
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      },
      formatExtraData: {
        setSelectedIndex,
        inventoryState,
        updateMap,
      },
    },
  ];

  /*getting Data from Api and setting to Inventory */
  useEffect(() => {
    dispatch(onGetInventory());
  }, []);

  /*updating the API and inventory */
  useEffect(() => {
    if (stockValue !== null || skuValue !== null) {
      let invObj = {
        inventory: {
          status: stockValue ?? Data[selectedIndex]?.inventory?.status,
          inventoryQuantity:
            skuValue || Data[selectedIndex]?.inventory?.inventoryQuantity,
        },
      };
      dispatch(updateInventory(idValue, invObj));
    }
  }, [stockValue, skuValue]);

  const pageOptions = {
    sizePerPage: 10,
    totalSize: Data.length,
    custom: true,
  };
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Inventory | Scrollit</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Ecommerce"
            count={Data.length}
            breadcrumbItem="Inventory"
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={InventoryColumns}
                    data={Data}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={Data}
                        columns={InventoryColumns}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="success"
                                    className="btn-rounded  mb-2 me-2"
                                    // onClick={ }
                                  >
                                    <i className="mdi mdi-plus me-1" />
                                    View Detailed Report &gt;
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="_id"
                                    data={Data}
                                    columns={InventoryColumns}
                                    headerClasses="table-light"
                                    classes={
                                      "table align-middle table-nowrap table-check "
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EcommerceInventory;
