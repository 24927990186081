import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import img7 from "../../../assets/images/product/img-7.png";
import img4 from "../../../assets/images/product/img-4.png";

const EcommerceOrdersModal = props => {
  const { isOpen, toggle, data } = props;
  let subTotal;
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <ModalHeader toggle={toggle}>Order Details</ModalHeader>
        <ModalBody>
          <p className="mb-2">
            <b>Product id: </b>
            <span className="text-primary">#SK2540</span>
          </p>
          <p className="mb-4">
            <b> Billing Name: </b>
            <span className="text-primary">{data?.customerId?.username}</span>
          </p>

          <div className="table-responsive">
            <Table className="table align-middle table-nowrap">
              <thead>
                <tr>
                  <th scope="col">Product</th>
                  <th scope="col">Product Name</th>
                  <th scope="col">Price</th>
                </tr>
              </thead>
              <tbody>
                {data?.orderItems?.map((ele, idx) => {
                  const price = ele?.product?.price;
                  const quantity = ele?.quantity;
                  const total = price * quantity;
                  subTotal += total;
                  return (
                    <tr key={idx}>
                      <th scope="row">
                        <div>
                          <img
                            src={ele?.product?.media[0]?.url}
                            alt=""
                            className="avatar-sm"
                          />
                        </div>
                      </th>
                      <td>
                        <div>
                          <h5 className="text-truncate font-size-14">
                            {ele?.product?.name}
                          </h5>
                          <p className="text-muted mb-0">
                            $ {price} x {quantity}
                          </p>
                        </div>
                      </td>
                      <td>$ {total}</td>
                    </tr>
                  );
                })}
                {/* <tr>
                  <th scope="row">
                    <div>
                      <img
                        src={data?.orderItems[0]?.product?.media[0]?.url}
                        alt=""
                        className="avatar-sm"
                      />
                    </div>
                  </th>
                  <td>
                    <div>
                      <h5 className="text-truncate font-size-14">
                        {data?.orderItems[0]?.product?.name}
                      </h5>
                      <p className="text-muted mb-0">
                        $ {price} x {quantity}
                      </p>
                    </div>
                  </td>
                  <td>$ {price * quantity}</td>
                </tr> */}

                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Sub Total:</h6>
                  </td>
                  <td>$ {data?.totalPrice}</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Shipping:</h6>
                  </td>
                  <td>Free</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <h6 className="m-0 text-end">Total:</h6>
                  </td>
                  <td>$ {data?.totalPrice}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </div>
    </Modal>
  );
};

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  data: PropTypes.object,
};

export default EcommerceOrdersModal;
