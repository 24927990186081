import {
  GET_CART_DATA,
  GET_CART_DATA_FAIL,
  GET_CART_DATA_SUCCESS,
  GET_ORDERS,
  GET_ORDERS_FAIL,
  GET_ORDERS_SUCCESS,
  ADD_NEW_ORDER,
  ADD_ORDER_SUCCESS,
  ADD_ORDER_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  DELETE_ORDER,
  DELETE_ALL_ORDERS,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_FAIL,
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  GET_CUSTOMERS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_SUCCESS,
  ADD_NEW_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  GET_SHOPS,
  GET_SHOPS_FAIL,
  GET_SHOPS_SUCCESS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_DETAIL_FAIL,
  GET_PRODUCT_DETAIL_SUCCESS,
  GET_PRODUCT_COMMENTS,
  GET_PRODUCT_COMMENTS_SUCCESS,
  GET_PRODUCT_COMMENTS_FAIL,
  ON_LIKE_COMMENT,
  ON_LIKE_COMMENT_SUCCESS,
  ON_LIKE_COMMENT_FAIL,
  ON_LIKE_REPLY,
  ON_LIKE_REPLY_SUCCESS,
  ON_LIKE_REPLY_FAIL,
  ON_ADD_REPLY,
  ON_ADD_REPLY_SUCCESS,
  ON_ADD_REPLY_FAIL,
  ON_ADD_COMMENT,
  ON_ADD_COMMENT_SUCCESS,
  ON_ADD_COMMENT_FAIL,
  GET_PRODUCT_LIST,
  ADD_NEW_PRODUCT_IN_LIST,
  ADD_PRODUCT_IN_LIST_SUCCESS,
  ADD_PRODUCT_IN_LIST_FAIL,
  UPDATE_PRODUCT_IN_LIST,
  UPDATE_PRODUCT_IN_LIST_SUCCESS,
  UPDATE_PRODUCT_IN_LIST_FAIL,
  DELETE_PRODUCT_IN_LIST,
  DELETE_PRODUCT_IN_LIST_SUCCESS,
  DELETE_PRODUCT_IN_LIST_FAIL,
  GET_PRODUCT_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAIL,
  IMPORT_CUSTOMERS,
  IMPORT_CUSTOMERS_SUCCESS,
  IMPORT_CUSTOMERS_FAIL,
  DELETE_ALL_CUSTOMERS,
  DELETE_ALL_CUSTOMERS_SUCCESS,
  DELETE_ALL_CUSTOMERS_FAIL,
  GET_INVENTORY,
  GET_INVENTORY_SUCCESS,
  GET_INVENTORY_FAIL,
  UPDATE_INVENTORY,
  UPDATE_INVENTORY_SUCCESS,
  UPDATE_INVENTORY_FAIL,
  GET_CUSTOMER_ORDER,
  GET_CUSTOMER_ORDER_SUCCESS,
  GET_CUSTOMER_ORDER_FAIL,
  GET_NOTES,
  GET_NOTES_SUCCESS,
  GET_NOTES_FAIL,
  ADD_NOTES,
  ADD_NOTES_SUCCESS,
  ADD_NOTES_FAIL,
  DELETE_ALL_NOTES,
  DELETE_ALL_NOTES_SUCCESS,
  DELETE_ALL_NOTES_FAIL,
  GET_COLLECTIONS,
  GET_COLLECTIONS_SUCCESS,
  GET_COLLECTIONS_FAIL,
  ADD_COLLECTION,
  ADD_COLLECTION_SUCCESS,
  ADD_COLLECTION_FAIL,
  UPDATE_COLLECTION,
  UPDATE_COLLECTION_SUCCESS,
  UPDATE_COLLECTION_FAIL,
  DELETE_COLLECTION,
  DELETE_COLLECTION_SUCCESS,
  DELETE_COLLECTION_FAIL,
  DELETE_ALL_ORDER_FAIL,
  DELETE_ALL_ORDER_SUCCESS,
  UPDATE_NOTES,
  UPDATE_NOTES_SUCCESS,
  UPDATE_NOTES_FAIL,
  DELETE_NOTE,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAIL,
  GET_CUSTOMER_INVOICE,
  GET_CUSTOMER_INVOICE_SUCCESS,
  GET_CUSTOMER_INVOICE_FAIL,
  DELETE_ALL_INVOICE,
  DELETE_ALL_INVOICE_SUCCESS,
  DELETE_ALL_INVOICE_FAIL,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAIL,
  GET_TASK,
  GET_TASK_SUCCESS,
  GET_TASK_FAIL,
  ADD_TASK,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAIL,
  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
  DOMAIN_AVAILABILITY,
  DOMAIN_AVAILABILITY_FAIL,
  DOMAIN_AVAILABILITY_SUCCESS,
  DOMAIN_SUGGESTION,
  DOMAIN_SUGGESTION_SUCCESS,
  DOMAIN_SUGGESTION_FAIL,
  DOMAIN_CLEAN_UP,
  BUY_DOMAIN,
  BUY_DOMAIN_SUCCESS,
  BUY_DOMAIN_FAIL
} from "./actionTypes";

export const getCollections = () => ({
  type: GET_COLLECTIONS,
});

export const getCollectionsSuccess = collections => ({
  type: GET_COLLECTIONS_SUCCESS,
  payload: collections,
});

export const getCollectionsFail = error => ({
  type: GET_COLLECTIONS_FAIL,
  payload: error,
});

export const addCollection = (collection, history) => ({
  type: ADD_COLLECTION,
  payload: { collection, history },
});

export const addCollectionSuccess = collection => ({
  type: ADD_COLLECTION_SUCCESS,
  payload: collection,
});

export const addCollectionFail = error => ({
  type: ADD_COLLECTION_FAIL,
  payload: error,
});

export const updateCollection = (collection, history) => ({
  type: UPDATE_COLLECTION,
  payload: { collection, history },
});

export const updateCollectionSuccess = collection => ({
  type: UPDATE_COLLECTION_SUCCESS,
  payload: collection,
});

export const updateCollectionFail = error => ({
  type: UPDATE_COLLECTION_FAIL,
  payload: error,
});

export const deleteCollection = (collectionId, history) => ({
  type: DELETE_COLLECTION,
  payload: { collectionId, history },
});

export const deleteCollectionSuccess = collectionId => ({
  type: DELETE_COLLECTION_SUCCESS,
  payload: collectionId,
});

export const deleteCollectionFail = error => ({
  type: DELETE_COLLECTION_FAIL,
  payload: error,
});

export const getProducts = () => ({
  type: GET_PRODUCTS,
});

export const getProductsSuccess = products => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
});

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
});

export const getProductList = () => ({
  type: GET_PRODUCT_LIST,
});

export const getProductListSuccess = productList => ({
  type: GET_PRODUCT_LIST_SUCCESS,
  payload: productList,
});

export const getProductListFail = error => ({
  type: GET_PRODUCTS_LIST_FAIL,
  payload: error,
});

export const addNewProductInList = product => ({
  type: ADD_NEW_PRODUCT_IN_LIST,
  payload: product,
});

export const addProductInListSuccess = product => ({
  type: ADD_PRODUCT_IN_LIST_SUCCESS,
  payload: product,
});

export const addProductInListFail = error => ({
  type: ADD_PRODUCT_IN_LIST_FAIL,
  payload: error,
});

export const updateProductInList = product => ({
  type: UPDATE_PRODUCT_IN_LIST,
  payload: product,
});

export const updateProductInListSuccess = product => ({
  type: UPDATE_PRODUCT_IN_LIST_SUCCESS,
  payload: product,
});

export const updateProductInListFail = error => ({
  type: UPDATE_PRODUCT_IN_LIST_FAIL,
  payload: error,
});

export const deleteProductInList = product => ({
  type: DELETE_PRODUCT_IN_LIST,
  payload: product,
});

export const deleteProductInListSuccess = product => ({
  type: DELETE_PRODUCT_IN_LIST_SUCCESS,
  payload: product,
});

export const deleteProductInListFail = error => ({
  type: DELETE_PRODUCT_IN_LIST_FAIL,
  payload: error,
});

export const getProductDetail = productId => ({
  type: GET_PRODUCT_DETAIL,
  productId,
});

export const getProductDetailSuccess = products => ({
  type: GET_PRODUCT_DETAIL_SUCCESS,
  payload: products,
});

export const getProductDetailFail = error => ({
  type: GET_PRODUCT_DETAIL_FAIL,
  payload: error,
});

export const getOrders = () => ({
  type: GET_ORDERS,
});

export const getOrdersSuccess = orders => ({
  type: GET_ORDERS_SUCCESS,
  payload: orders,
});

export const getOrdersFail = error => ({
  type: GET_ORDERS_FAIL,
  payload: error,
});

export const addNewOrder = order => ({
  type: ADD_NEW_ORDER,
  payload: order,
});

export const addOrderSuccess = order => ({
  type: ADD_ORDER_SUCCESS,
  payload: order,
});

export const addOrderFail = error => ({
  type: ADD_ORDER_FAIL,
  payload: error,
});

export const updateOrder = order => ({
  type: UPDATE_ORDER,
  payload: order,
});

export const updateOrderSuccess = order => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
});

export const updateOrderFail = error => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
});

export const deleteOrder = order => ({
  type: DELETE_ORDER,
  payload: order,
});

export const deleteOrderSuccess = order => ({
  type: DELETE_ORDER_SUCCESS,
  payload: order,
});

export const deleteOrderFail = error => ({
  type: DELETE_ORDER_FAIL,
  payload: error,
});

export const getCartData = () => ({
  type: GET_CART_DATA,
});

export const getCartDataSuccess = cartData => ({
  type: GET_CART_DATA_SUCCESS,
  payload: cartData,
});

export const getCartDataFail = error => ({
  type: GET_CART_DATA_FAIL,
  payload: error,
});

export const getCustomers = () => ({
  type: GET_CUSTOMERS,
});

export const getCustomersSuccess = customers => ({
  type: GET_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const getCustomersFail = error => ({
  type: GET_CUSTOMERS_FAIL,
  payload: error,
});

export const addNewCustomer = customer => ({
  type: ADD_NEW_CUSTOMER,
  payload: customer,
});

export const addCustomerSuccess = customer => ({
  type: ADD_CUSTOMER_SUCCESS,
  payload: customer,
});

export const addCustomerFail = error => ({
  type: ADD_CUSTOMER_FAIL,
  payload: error,
});

export const updateCustomer = customer => ({
  type: UPDATE_CUSTOMER,
  payload: customer,
});

export const updateCustomerSuccess = customer => ({
  type: UPDATE_CUSTOMER_SUCCESS,
  payload: customer,
});

export const updateCustomerFail = error => ({
  type: UPDATE_CUSTOMER_FAIL,
  payload: error,
});

export const deleteCustomer = customer => ({
  type: DELETE_CUSTOMER,
  payload: customer,
});

export const deleteCustomerSuccess = customer => ({
  type: DELETE_CUSTOMER_SUCCESS,
  payload: customer,
});

export const deleteCustomerFail = error => ({
  type: DELETE_CUSTOMER_FAIL,
  payload: error,
});

export const importCustomers = customers => ({
  type: IMPORT_CUSTOMERS,
  payload: customers,
});

export const importCustomerSuccess = customers => ({
  type: IMPORT_CUSTOMERS_SUCCESS,
  payload: customers.customerInfo,
});

export const importCustomerFail = error => ({
  type: IMPORT_CUSTOMERS_FAIL,
  payload: error,
});

export const deleteAllCustomers = () => ({
  type: DELETE_ALL_CUSTOMERS,
});

export const deleteAllOrders = () => ({
  type: DELETE_ALL_ORDERS,
});

export const deleteAllOrdersSuccess = () => ({
  type: DELETE_ALL_ORDER_SUCCESS,
});

export const deleteAllOrdersFail = () => ({
  type: DELETE_ALL_ORDER_FAIL,
});

export const deleteAllCustomersSuccess = () => ({
  type: DELETE_ALL_CUSTOMERS_SUCCESS,
});

export const deleteAllCustomersFail = error => ({
  type: DELETE_ALL_CUSTOMERS_FAIL,
  payload: error,
});

export const getShops = () => ({
  type: GET_SHOPS,
});

export const getShopsSuccess = shops => ({
  type: GET_SHOPS_SUCCESS,
  payload: shops,
});

export const getShopsFail = error => ({
  type: GET_SHOPS_FAIL,
  payload: error,
});

export const getProductComments = () => ({
  type: GET_PRODUCT_COMMENTS,
});

export const getProductCommentsSuccess = comments => ({
  type: GET_PRODUCT_COMMENTS_SUCCESS,
  payload: comments,
});

export const getProductCommentsFail = error => ({
  type: GET_PRODUCT_COMMENTS_FAIL,
  payload: error,
});

export const onLikeComment = (commentId, productId) => ({
  type: ON_LIKE_COMMENT,
  payload: { commentId, productId },
});

export const onLikeCommentSuccess = comments => ({
  type: ON_LIKE_COMMENT_SUCCESS,
  payload: comments,
});

export const onLikeCommentFail = error => ({
  type: ON_LIKE_COMMENT_FAIL,
  payload: error,
});

export const onLikeReply = (commentId, productId, replyId) => ({
  type: ON_LIKE_REPLY,
  payload: { commentId, productId, replyId },
});

export const onLikeReplySuccess = comments => ({
  type: ON_LIKE_REPLY_SUCCESS,
  payload: comments,
});

export const onLikeReplyFail = error => ({
  type: ON_LIKE_REPLY_FAIL,
  payload: error,
});

export const onAddReply = (commentId, productId, replyText) => ({
  type: ON_ADD_REPLY,
  payload: { commentId, productId, replyText },
});

export const onAddReplySuccess = comments => ({
  type: ON_ADD_REPLY_SUCCESS,
  payload: comments,
});

export const onAddReplyFail = error => ({
  type: ON_ADD_REPLY_FAIL,
  payload: error,
});

export const onAddComment = (productId, commentText) => ({
  type: ON_ADD_COMMENT,
  payload: { productId, commentText },
});

export const onAddCommentSuccess = comments => ({
  type: ON_ADD_COMMENT_SUCCESS,
  payload: comments,
});

export const onAddCommentFail = error => ({
  type: ON_ADD_COMMENT_FAIL,
  payload: error,
});

/*INVENTORY*/
export const getInventory = () => ({
  type: GET_INVENTORY,
});

export const getInventorySuccess = inventory => ({
  type: GET_INVENTORY_SUCCESS,
  payload: inventory.data,
});

export const getInventoryFail = error => ({
  type: GET_INVENTORY_FAIL,
  payload: error,
});

export const updateInventory = (updateId, update) => ({
  type: UPDATE_INVENTORY,
  payload: { updateId, update },
});

export const updateInventorySuccess = update => ({
  type: UPDATE_INVENTORY_SUCCESS,
  payload: update.data,
});

export const updateInventoryFail = error => ({
  type: UPDATE_INVENTORY_FAIL,
  payload: error,
});

/*Customer Details*/
export const getCustomerOrder = CustomerId => ({
  type: GET_CUSTOMER_ORDER,
  payload: CustomerId,
});

export const getCustomerOrderSuccess = orderId => ({
  type: GET_CUSTOMER_ORDER_SUCCESS,
  payload: orderId,
});

export const getCustomerOrderFail = error => ({
  type: GET_CUSTOMER_ORDER_FAIL,
  payload: error,
});

export const getNotes = customerId => ({
  type: GET_NOTES,
  payload: customerId,
});

export const getNotesSuccess = notes => ({
  type: GET_NOTES_SUCCESS,
  payload: notes,
});

export const getNotesFail = error => ({
  type: GET_NOTES_FAIL,
  payload: error,
});

export const addNotes = (customerId, notes) => ({
  type: ADD_NOTES,
  payload: { customerId, notes },
});

export const addNotesSuccess = notes => ({
  type: ADD_NOTES_SUCCESS,
  payload: notes,
});

export const addNotesFail = error => ({
  type: ADD_NOTES_FAIL,
  payload: error,
});

export const deleteAllNotes = customerId => ({
  type: DELETE_ALL_NOTES,
  payload: customerId,
});

export const deleteAllNotesSuccess = () => ({
  type: DELETE_ALL_NOTES_SUCCESS,
});

export const deleteAllNotesFail = error => ({
  type: DELETE_ALL_NOTES_FAIL,
  payload: error,
});

export const updateNotes = (customerId, noteId, note) => ({
  type: UPDATE_NOTES,
  payload: { customerId, noteId, note },
});

export const updateNotesSuccess = (noteId, note) => ({
  type: UPDATE_NOTES_SUCCESS,
  payload: { noteId, note },
});

export const updateNotesFail = error => ({
  type: UPDATE_NOTES_FAIL,
  payload: error,
});

export const deleteNote = (customerId, noteId) => ({
  type: DELETE_NOTE,
  payload: { customerId, noteId },
});

export const deleteNoteSuccess = noteId => ({
  type: DELETE_NOTE_SUCCESS,
  payload: noteId,
});

export const deleteNoteFail = error => ({
  type: DELETE_NOTE_FAIL,
  payload: error,
});

export const getCustomerInvoice = () => ({
  type: GET_CUSTOMER_INVOICE,
});

export const getCustomerInvoiceSuccess = invoice => ({
  type: GET_CUSTOMER_INVOICE_SUCCESS,
  payload: invoice,
});

export const getCustomerInvoiceFail = error => ({
  type: GET_CUSTOMER_INVOICE_FAIL,
  payload: error,
});

export const deleteAllInvoice = customerId => ({
  type: DELETE_ALL_INVOICE,
  payload: customerId,
});

export const deleteAllInvoiceSuccess = () => ({
  type: DELETE_ALL_INVOICE_SUCCESS,
});

export const deleteAllInvoiceFail = error => ({
  type: DELETE_ALL_INVOICE_FAIL,
  payload: error,
});

export const deleteInvoice = (customerId, invoiceId) => ({
  type: DELETE_INVOICE,
  payload: { customerId, invoiceId },
});

export const deleteInvoiceSuccess = invoiceId => ({
  type: DELETE_INVOICE_SUCCESS,
  payload: invoiceId,
});

export const deleteInvoiceFail = error => ({
  type: DELETE_INVOICE_FAIL,
  payload: error,
});

export const getTasks = customerId => ({
  type: GET_TASK,
  payload: customerId,
});

export const getTasksSuccess = tasks => ({
  type: GET_TASK_SUCCESS,
  payload: tasks,
});
export const getTasksFail = error => ({
  type: GET_TASK_FAIL,
  payload: error,
});

export const addTask = (customerId, task) => ({
  type: ADD_TASK,
  payload: { customerId, task },
});

export const addTaskSuccess = task => ({
  type: ADD_TASK_SUCCESS,
  payload: task,
});

export const addTaskFail = error => ({
  type: ADD_TASK_FAIL,
  payload: error,
});

export const deleteTask = (customerId, taskId) => ({
  type: DELETE_TASK,
  payload: { customerId, taskId },
});

export const deleteTaskSuccess = taskId => ({
  type: DELETE_TASK_SUCCESS,
  payload: taskId,
});

export const deleteTaskFail = error => ({
  type: DELETE_TASK_FAIL,
  payload: error,
});

export const updateTask = (customerId, taskId, task, status) => ({
  type: UPDATE_TASK,
  payload: { customerId, taskId, task, status },
});

export const updateTaskSuccess = (taskId, task, status) => ({
  type: UPDATE_TASK_SUCCESS,
  payload: { taskId, task, status },
});

export const updateTaskFail = error => ({
  type: UPDATE_TASK_FAIL,
  payload: error,
});

//get domain
export const domainAvailability = siteName => ({
  type: DOMAIN_AVAILABILITY,
  payload: siteName,
});

export const domainAvailabilitySuccess = response => ({
  type: DOMAIN_AVAILABILITY_SUCCESS,
  payload: response,
});

export const domainAvailabilityFail = error => ({
  type: DOMAIN_AVAILABILITY_FAIL,
  payload: error,
});

//domain suggest
export const domainSuggestion = siteName => ({
  type: DOMAIN_SUGGESTION,
  payload: siteName,
});

export const domainSuggestionSuccess = response => ({
  type: DOMAIN_SUGGESTION_SUCCESS,
  payload: response,
});

export const domainSuggestionFail = error => ({
  type: DOMAIN_SUGGESTION_FAIL,
  payload: error,
});

export const domainCleanup = () => ({
  type: DOMAIN_CLEAN_UP,
});

export const buyDomain=domainName=>({
  type:BUY_DOMAIN,
  payload:domainName
})

export const buyDomainSuccess=response=>({
  type:BUY_DOMAIN_SUCCESS,
  payload:response
})

export const buyDomainFail=error=>({
  type:BUY_DOMAIN_FAIL,
  payload:error
})