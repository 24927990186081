import React from "react";
import PropTypes from "prop-types";
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";

const suggestionCard = ({ siteName, siteData }) => {
  return (
    <>
      <Col sm={3}>
        <Card outline color="primary" className="border">
          <CardBody>
            <CardTitle className="mt-0">
              <i className="bx bx-check-shield me-2 font-size-22" />
              {/* <UncontrolledTooltip placement="bottom" target={siteData}>
                Domain privacy protection hides your personal information.It
                helps to avoid spam and keeps your data safe from the public.
              </UncontrolledTooltip> */}
              {siteName}
            </CardTitle>
            <Link
              to={{
                pathname: `/ecommerce-buy-domain-name=${siteName}`,
                state: { siteData: siteData },
              }}
              className="btn btn-primary"
            >
              Bug Now
            </Link>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

suggestionCard.propTypes = {
  siteName: PropTypes.string,
  siteData: PropTypes.object,
};

export default suggestionCard;
