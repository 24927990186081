export const activityLog = [
  {
    text: "qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq.",
    time: "Sep 15,2022,4:45 PM",
  },
  {
    text: "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww",
    time: "Oct 25,2022,2:45 AM",
  },
  {
    text: "wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww",
    time: "Jan 05,2022,10:00 AM",
  },
];

export const NoteData = [
  {
    data: "Qwerty",
    date: "Nov 1,2022",
  },
  {
    data: "Copyright (C) Microsoft Corporation. All rights reserved.",
    date: "oct 10,2022",
  },
  {
    data: "Try the new cross-platform PowerShell https://aka.ms/pscore6",
    date: "Sept 23,2022",
  },
  {
    data: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Cupiditate ut consequuntur necessitatibus nam perferendis ducimus eligendi magnam qui unde sequi, vitae ex repellat porro. Suscipit iste cumque voluptate doloribus ea beatae illum perferendis molestias, repellat hic unde magnam facere in! Beatae excepturi mollitia necessitatibus animi, fugit perferendis, corrupti sit dolore impedit magnam consequuntur! Odio omnis vel nihil doloremque, qui eius esse architecto deserunt laudantium inventore velit cumque quo sed rerum delectus dignissimos sunt dolores! Alias, dicta animi autem similique eveniet eaque ipsa quos minus rem numquam hic expedita, odio incidunt illo excepturi distinctio repudiandae obcaecati molestias quibusdam eos in quod. Non obcaecati quas animi odio odit, tempore, ullam nihil quaerat totam eius dolorem rem neque illo impedit, rerum minima ipsam assumenda itaque nisi molestias facilis. Ipsa laudantium molestiae qui perferendis. Expedita, adipisci autem. Praesentium dolorem sunt iure minima, similique harum est odit aliquam architecto aspernatur perferendis voluptas porro minus optio ipsum totam nihil autem sed accusantium incidunt culpa sit reiciendis. Perspiciatis modi quod reiciendis! Laboriosam eos nulla iusto reprehenderit doloribus, aspernatur tenetur magnam tempora cumque laudantium harum, veritatis sequi quibusdam natus ex accusantium. Libero, optio in quos repellat voluptate maiores perspiciatis impedit dignissimos temporibus vitae incidunt architecto exercitationem accusantium cum consequatur ex numquam voluptatum. Quaerat, itaque accusantium omnis at animi porro dignissimos aperiam explicabo voluptate, ea deserunt magni distinctio! Soluta nam fuga optio vel?",
    date: "Jan 1,2022",
  },
];
