import React from "react"
import { useEffect } from "react";
import MetaTags from 'react-meta-tags';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { verifyRegisteredUser } from "store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

const ConfirmMail = () => {
  const { token } = useParams();
  const dispatch = useDispatch(); 
  const history = useHistory();

  const { isVerified } = useSelector(state => ({
    isVerified: state.Account.isVerified
  }));

  useEffect(() => {
    dispatch(verifyRegisteredUser(token, history));
  }, [token]);

  return (
    <React.Fragment>
      <MetaTags>
        <title>Confirm Mail | Scrollit</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">

        {isVerified ? (<Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="F20"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
                <p className="mt-3">{isVerified} We build products for Digital Age</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Success !</h4>
                        <p className="text-muted">
                          Wohoo! You are successfully verified!
                        </p>
                        <div className="mt-4">
                          <Link to="/login" className="btn btn-success">
                            Back to Home
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Scrollit. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Scrollit
                </p>
              </div>
            </Col>
          </Row>
        </Container>) : (<Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="F20"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
                <p className="mt-3">{isVerified} We build products for Digital Age</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Failed !</h4>
                        <p className="text-muted">
                        We are sorry, Token is either invalid or expired. Please try again.
                        </p>
                        <div className="mt-4">
                          <Link to="/login" className="btn btn-success">
                            Back to Home
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Scrollit. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Scrollit
                </p>
              </div>
            </Col>
          </Row>
        </Container>) }
      </div>
    </React.Fragment>
  )
}

export default ConfirmMail
