import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  VERIFY_REGISTER_USER,
  VERIFY_REGISTER_USER_SUCCESSFUL,
  VERIFY_REGISTER_USER_FAILED
} from "./actionTypes"

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history },
  }
}

export const registerUserSuccessful = user => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const registerUserFailed = user => {
  return {
    type: REGISTER_USER_FAILED,
    payload: user,
  }
}

export const verifyRegisteredUser = (token, history) => {
  return {
    type: VERIFY_REGISTER_USER,
    payload: { token, history },
  }
}

export const verifyRegisteredUserSuccess = user => {
  return {
    type: VERIFY_REGISTER_USER_SUCCESSFUL,
    payload: user,
  }
}

export const verifyRegisteredUserFailed = user => {
  return {
    type: VERIFY_REGISTER_USER_FAILED,
    payload: user,
  }
}
