/** Get Dashboard Chart data */
export const API_SUCCESS = "API_SUCCESS";
export const API_FAIL = "API_FAIL";
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const GET_ORDERS_COUNT_DATA = "GET_ORDERS_COUNT_DATA";
export const GET_ORDERS_COUNT_DATA_SUCCESS = "GET_ORDERS_COUNT_DATA_SUCCESS";
export const GET_ORDERS_COUNT_DATA_ERROR = "GET_ORDERS_COUNT_DATA_ERROR";
export const GET_REVENUE_TOTAL_DATA = "GET_REVENUE_TOTAL_DATA";
export const GET_REVENUE_TOTAL_DATA_SUCCESS = "GET_REVENUE_TOTAL_DATA_SUCCESS";
export const GET_REVENUE_TOTAL_DATA_ERROR = "GET_REVENUE_TOTAL_DATA_ERROR";
export const GET_ORDER_TOTAL_AVERAGE_PRICE = "GET_ORDER_TOTAL_AVERAGE_PRICE";
export const GET_ORDER_TOTAL_AVERAGE_PRICE_SUCCESS = "GET_ORDER_TOTAL_AVERAGE_PRICE_SUCCESS";
export const GET_ORDER_TOTAL_AVERAGE_PRICE_ERROR = "GET_ORDER_TOTAL_AVERAGE_PRICE_ERROR";
