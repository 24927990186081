import { useFormik } from "formik/dist";
import React from "react"
import MetaTags from 'react-meta-tags';
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom"
import { Card, CardBody, Col, Container, Form, FormFeedback, Input, Row, Label } from "reactstrap"
import { userResetPassword } from "store/actions";
import * as Yup from "yup";

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"

const ResetPasswordConfirmMail = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmPassword: ''
    },

    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Your Password").matches(
        ("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})"),
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
      confirmPassword: Yup.string().required("Please Enter Your confirmpassword").oneOf([Yup.ref("password"), null], "Confirm Password does not match")
    }),


    onSubmit: (values) => {
      const signupObj= {
        password: values.password,
        token
      }
    dispatch(userResetPassword(signupObj, history))
  }
  });


  return (
    <React.Fragment>
      <MetaTags>
        <title>Reset Password | Scrollit</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">

        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logodark}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="F20"
                    className="auth-logo-light mx-auto"
                  />
                </Link>
                <p className="mt-3">We build products for Digital Age</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();

                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Password <span style={{color:"red"}}>*</span></Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Confirm Password <span style={{color:"red"}}>*</span></Label>
                        <Input
                          name="confirmPassword"
                          type="password"
                          placeholder="Enter Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.confirmPassword || ""}
                          invalid={
                            validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false
                          }
                        />
                        {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Reset Password
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Scrollit{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} Scrollit. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Scrollit
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPasswordConfirmMail
