import { call, put, takeEvery } from "redux-saga/effects";
import showToast from "helpers/toast_helper";

// Ecommerce Redux States
import {
  GET_CART_DATA,
  GET_CUSTOMERS,
  GET_ORDERS,
  GET_PRODUCT_DETAIL,
  GET_PRODUCTS,
  GET_SHOPS,
  ADD_NEW_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER,
  ADD_NEW_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_CUSTOMER,
  GET_PRODUCT_COMMENTS,
  ON_LIKE_COMMENT,
  ON_LIKE_REPLY,
  ON_ADD_REPLY,
  ON_ADD_COMMENT,
  GET_PRODUCT_LIST,
  UPDATE_PRODUCT_IN_LIST,
  DELETE_PRODUCT_IN_LIST,
  ADD_NEW_PRODUCT_IN_LIST,
  IMPORT_CUSTOMERS,
  DELETE_ALL_CUSTOMERS,
  GET_COLLECTIONS,
  ADD_COLLECTION,
  UPDATE_COLLECTION,
  DELETE_COLLECTION,
  DELETE_ALL_ORDERS,
  GET_INVENTORY,
  UPDATE_INVENTORY,
  GET_CUSTOMER_ORDER,
  GET_NOTES,
  ADD_NOTES,
  DELETE_ALL_NOTES,
  UPDATE_NOTES,
  DELETE_NOTE,
  GET_CUSTOMER_INVOICE,
  DELETE_INVOICE,
  DELETE_ALL_INVOICE,
  GET_TASK,
  ADD_TASK,
  DELETE_TASK,
  UPDATE_TASK,
  DOMAIN_AVAILABILITY,
  DOMAIN_SUGGESTION,
  BUY_DOMAIN,
} from "./actionTypes";
import {
  getCartDataFail,
  getCartDataSuccess,
  getCustomersFail,
  getCustomersSuccess,
  getOrdersFail,
  getOrdersSuccess,
  getProductDetailFail,
  getProductDetailSuccess,
  getProductsFail,
  getProductsSuccess,
  getShopsFail,
  getShopsSuccess,
  addOrderFail,
  addOrderSuccess,
  updateOrderSuccess,
  updateOrderFail,
  deleteOrderSuccess,
  deleteOrderFail,
  addCustomerFail,
  addCustomerSuccess,
  updateCustomerSuccess,
  updateCustomerFail,
  deleteCustomerSuccess,
  deleteCustomerFail,
  getProductCommentsSuccess,
  getProductCommentsFail,
  onLikeCommentSuccess,
  onLikeCommentFail,
  onLikeReplySuccess,
  onLikeReplyFail,
  onAddReplySuccess,
  onAddReplyFail,
  onAddCommentSuccess,
  onAddCommentFail,
  getProductListSuccess,
  getProductListFail,
  updateProductInListSuccess,
  updateProductInListFail,
  deleteProductInListSuccess,
  deleteProductInListFail,
  addProductInListSuccess,
  addProductInListFail,
  importCustomerSuccess,
  importCustomerFail,
  deleteAllCustomersSuccess,
  deleteAllCustomersFail,
  getCollectionsSuccess,
  getCollectionsFail,
  updateCollectionSuccess,
  updateCollectionFail,
  deleteCollectionSuccess,
  deleteCollectionFail,
  addCollectionSuccess,
  addCollectionFail,
  deleteAllOrders,
  deleteAllOrdersSuccess,
  deleteAllOrdersFail,
  getInventorySuccess,
  getInventoryFail,
  updateInventorySuccess,
  updateInventoryFail,
  getCustomerOrderSuccess,
  getCustomerOrderFail,
  getNotesSuccess,
  getNotesFail,
  addNotesSuccess,
  addNotesFail,
  deleteAllNotesSuccess,
  deleteAllNotesFail,
  updateNotesSuccess,
  updateNotesFail,
  deleteNoteSuccess,
  deleteNoteFail,
  getCustomerInvoiceSuccess,
  getCustomerInvoiceFail,
  deleteInvoiceSuccess,
  deleteInvoiceFail,
  deleteAllInvoiceSuccess,
  deleteAllInvoiceFail,
  getTasksSuccess,
  getTasksFail,
  addTaskSuccess,
  addTaskFail,
  deleteTaskFail,
  deleteTaskSuccess,
  updateTaskSuccess,
  updateTaskFail,
  domainAvailabilitySuccess,
  domainAvailabilityFail,
  domainSuggestionSuccess,
  domainSuggestionFail,
  buyDomainSuccess,
  buyDomainFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  getCartData,
  getOrders,
  getProducts,
  getShops,
  getProductDetail,
  addNewOrder,
  updateOrder,
  deleteOrder,
  getProductComents as getProductComentsApi,
  onLikeComment as onLikeCommentApi,
  onLikeReply as onLikeReplyApi,
  onAddReply as onAddReplyApi,
  onAddComment as onAddCommentApi,
  getInventory,
  updateInventory,
  getCustomerOrder,
  deleteAllOrdersCall,
  getAllNotes,
  onAddNotes as onAddNotesApi,
  deleteAllNotes as deleteAllNotesApi,
  updateNote as updateNoteApi,
  deleteNote as deleteNoteApi,
  customerInvoice,
  deleteAllInvoice as deleteAllInvoiceApi,
  deleteInvoice as deleteInvoiceApi,
  onAddTask as onAddTaskApi,
  onGetTask,
  deleteTaskApi,
  updateTaskApi,
  domainAvailabilityApi,
  domainSuggestionApi,
  buyDomainApi,
} from "helpers/fakebackend_helper";

import {
  getProductList,
  addNewProductInList,
  updateProductInList,
  deleteProductInList,
  getCustomers,
  addNewCustomer,
  updateCustomer,
  deleteCustomer,
  importCustomers,
  deleteEveryCustomer,
  getCollections,
  updateCollection,
  deleteColletion,
  addCollection,
} from "helpers/backend_helper";
import { showToastError, showToastSuccess } from "helpers/toastBuilder";

function* fetchProducts() {
  try {
    const response = yield call(getProducts);
    yield put(getProductsSuccess(response));
  } catch (error) {
    yield put(getProductsFail(error));
  }
}

function* fetchProductDetail({ productId }) {
  try {
    const response = yield call(getProductDetail, productId);
    yield put(getProductDetailSuccess(response));
  } catch (error) {
    yield put(getProductDetailFail(error));
  }
}

function* fetchProductList() {
  try {
    const response = yield call(getProductList);
    yield put(getProductListSuccess(response.data.products));
  } catch (error) {
    yield put(getProductListFail(error));
  }
}

function* onUpdateProductInList({ payload: product }) {
  try {
    const response = yield call(updateProductInList, product);
    yield put(updateProductInListSuccess(product));
  } catch (error) {
    yield put(updateProductInListFail(error));
  }
}

function* onDeleteProductInList({ payload: product }) {
  try {
    const response = yield call(deleteProductInList, product);
    yield put(deleteProductInListSuccess(product));
    showToastSuccess("Product deleted successfully", "Success");
  } catch (error) {
    yield put(deleteProductInListFail(error));
    showToastError("Product failed to get deleted", "Error");
  }
}

function* onAddNewProductInList({ payload: product }) {
  try {
    const response = yield call(addNewProductInList, product);
    yield put(addProductInListSuccess(response));
  } catch (error) {
    yield put(addProductInListFail(error));
  }
}

function* fetchOrders() {
  try {
    const response = yield call(getOrders);
    yield put(getOrdersSuccess(response.data));
  } catch (error) {
    yield put(getOrdersFail(error));
  }
}

function* fetchCartData() {
  try {
    const response = yield call(getCartData);
    yield put(getCartDataSuccess(response));
  } catch (error) {
    yield put(getCartDataFail(error));
  }
}

function* fetchCustomers() {
  try {
    const response = yield call(getCustomers);
    yield put(getCustomersSuccess(response.data.customers));
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}

function* onUpdateCustomer({ payload: customer }) {
  try {
    const response = yield call(updateCustomer, customer);
    yield put(updateCustomerSuccess(customer));
  } catch (error) {
    yield put(updateCustomerFail(error));
  }
}

function* onDeleteCustomer({ payload: customer }) {
  try {
    const response = yield call(deleteCustomer, customer);
    yield put(deleteCustomerSuccess(customer));
  } catch (error) {
    yield put(deleteCustomerFail(error));
  }
}

function* onDeleteAllCustomers() {
  try {
    const response = yield call(deleteEveryCustomer);
    yield put(deleteAllCustomersSuccess());
  } catch (error) {
    yield put(deleteAllCustomersFail(error));
  }
}

function* onImportCustomers({ payload: customers }) {
  try {
    const response = yield call(importCustomers, customers);
    yield put(importCustomerSuccess(customers));
  } catch (error) {
    yield put(importCustomerFail(error));
  }
}

function* onAddNewCustomer({ payload: customer }) {
  try {
    console.log('addnew customer called ', customer);
    const response = yield call(addNewCustomer, customer);
    yield put(addCustomerSuccess(customer));
    showToast("Customer added sucessfully", "Customer Added");
  } catch (error) {
    console.log('error check ', error);
    showToastError('Customer Failed To Add ', "Error");
    yield put(addCustomerFail(error));
  }
}

function* fetchShops() {
  try {
    const response = yield call(getShops);
    yield put(getShopsSuccess(response));
  } catch (error) {
    yield put(getShopsFail(error));
  }
}

function* onUpdateOrder({ payload: order }) {
  try {
    const response = yield call(updateOrder, order);
    showToast("Order updated successfully!", "Order updated");
    yield put(
      updateOrderSuccess({
        ...response.data,
        orderItems: order.orderItems,
      })
    );
  } catch (error) {
    yield put(updateOrderFail(error));
    showToast(`Order failed to Update ${error}`, "Error updating", "error");
  }
}

function* onDeleteOrder({ payload: order }) {
  try {
    const response = yield call(deleteOrder, order);
    showToast("Order deleted sucessfully", "Order Deletion");
    yield put(deleteOrderSuccess(order));
  } catch (error) {
    yield put(deleteOrderFail(error));
    showToast(`Order failed to delete ${error}`, "Error deleting", "error");
  }
}

function* onDeleteAllOrders() {
  try {
    const response = yield call(deleteAllOrdersCall);
    showToast("All orders deleted sucessfully", "All Orders Deletion");
    yield put(deleteAllOrdersSuccess());
  } catch {
    yield put(deleteAllOrdersFail());
    showToast(`Order failed to delete ${error}`, "Error deleting", "error");
  }
}

function* onAddNewOrder({ payload: order }) {
  try {
    const response = yield call(addNewOrder, order);
    showToast(
      `order created successfully with order id of ${response.data.order._id}`,
      "Order creation successfully"
    );
    yield put(addOrderSuccess(response.data.order));
  } catch (error) {
    yield put(addOrderFail(error));
    showToast(`Failed to add order ${error}`, "Error Adding Order", "error");
  }
}

function* fetchCollections() {
  try {
    const products = yield call(getProductList);
    const response = yield call(getCollections);
    yield put(
      getCollectionsSuccess([
        {
          name: "All Products",
          _id: "all-products",
          icon: "ballot",
          color: "#7A8D96",
          products: products.data.products.map(product => product._id),
        },
        ...response.data,
      ])
    );
  } catch (error) {
    yield put(getCollectionsFail);
  }
}

function* onUpdateCollection({ payload: { collection, history } }) {
  try {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const response = yield call(updateCollection, collection, config);
    yield put(updateCollectionSuccess(response.data));
    showToastSuccess('Collection Updated successfully', 'Success')
    history.push("/ecommerce-collections")
  } catch (error) {
    yield put(updateCollectionFail(error));
    showToastError('Sorry! Failed to update the collection', 'error');
    history.push("/ecommerce-collections")
  }
}

function* onDeleteCollection({ payload: { collectionId, history }}) {
  try {
    const response = yield call(deleteColletion, collectionId);
    yield put(deleteCollectionSuccess(collectionId));
    history.push("/ecommerce-collections")
    showToastSuccess('Collection Deleted successfully', 'Success')
  } catch (error) {
    yield put(deleteCollectionFail(error));
    history.push("/ecommerce-collections")
    showToastError('Sorry! Failed to delete the collection', 'error');
  }
}

function* onAddCollection({ payload: { collection, history } }) {
  try {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const response = yield call(addCollection, collection, config);
    yield put(addCollectionSuccess(response));
    showToastSuccess('Collection Added successfully', 'Success')
    history.push("/ecommerce-collections")
  } catch (error) {
    yield put(addCollectionFail(error));
    showToastError('Sorry! Failed to Add the collection', 'error');
  }
}

function* getProductComents() {
  try {
    // todo - add product Id to the payload and api
    const response = yield call(getProductComentsApi);
    yield put(getProductCommentsSuccess(response));
  } catch (error) {
    yield put(getProductCommentsFail(error));
  }
}

function* onLikeComment({ payload: { commentId, productId } }) {
  try {
    // todo - add product Id to the payload and api
    const response = yield call(onLikeCommentApi, commentId, productId);
    yield put(onLikeCommentSuccess(response));
  } catch (error) {
    yield put(onLikeCommentFail(error));
  }
}

function* onLikeReply({ payload: { commentId, productId, replyId } }) {
  try {
    // todo - add product Id to the payload and api
    const response = yield call(onLikeReplyApi, commentId, productId, replyId);
    yield put(onLikeReplySuccess(response));
  } catch (error) {
    yield put(onLikeReplyFail(error));
  }
}

function* onAddReply({ payload: { commentId, productId, replyText } }) {
  try {
    const response = yield call(onAddReplyApi, commentId, productId, replyText);
    yield put(onAddReplySuccess(response));
  } catch (error) {
    yield put(onAddReplyFail(error));
  }
}

function* onAddComment({ payload: { productId, commentText } }) {
  try {
    const response = yield call(onAddCommentApi, productId, commentText);
    yield put(onAddCommentSuccess(response));
  } catch (error) {
    yield put(onAddCommentFail(error));
  }
}

/*Inventory*/
function* fetchInventory() {
  try {
    const response = yield call(getInventory);
    yield put(getInventorySuccess(response));
  } catch (error) {
    yield put(getInventoryFail(error));
  }
}

function* onUpdateInventory({ payload: { updateId, update } }) {
  try {
    const response = yield call(updateInventory, updateId, update);
    yield put(updateInventorySuccess(response));
    if (response.statusCode === "10000") {
      showToastSuccess("changes succesfully updated", "SUCCESS");
    }
  } catch (error) {
    yield put(updateInventoryFail(error));
    if (error.response.data.statusCode === "10001") {
      showToastError(`Failed to update`, "FAILED");
    } else if (error.response.data.statusCode === "10002") {
      showToastError("Please retry", "FAILED");
    } else if (error.response.data.statusCode === "10003") {
      showToastError("Invalid Access Token", "FAILED");
    } else {
      showToastError("changes is not updated", "FAILED");
    }
  }
}

/*Customer Detail */
/*Order */
function* fetchCustomerOrder({ payload: CustomerId }) {
  try {
    const response = yield call(getCustomerOrder, CustomerId);
    yield put(getCustomerOrderSuccess(response));
    console.log(respose);
  } catch (error) {
    yield put(getCustomerOrderFail(error));
  }
}

/*Notes */
function* fetchNotes({ payload: CustomerId }) {
  try {
    const response = yield call(getAllNotes, CustomerId);
    yield put(getNotesSuccess(response));
  } catch (error) {
    yield put(getNotesFail(error));
  }
}

function* onAddNotes({ payload: { customerId, notes } }) {
  try {
    const response = yield call(onAddNotesApi, customerId, notes);
    yield put(addNotesSuccess(response));
    showToast("Note Added Successfully!", " Success");
  } catch (error) {
    yield put(addNotesFail(error));
    showToast(`Note failed to Update ${error}`, "Error updating", "error");
  }
}

function* onDeleteAllNotes({ payload: customerId }) {
  try {
    const response = yield call(deleteAllNotesApi, customerId);
    yield put(deleteAllNotesSuccess());
    showToast("All Notes Deleted Successfully!", "Deleted !");
  } catch (error) {
    yield put(deleteAllNotesFail(error));
    showToast(`Notes failed to Delete ${error}`, "Error Deleting", "error");
  }
}

function* onUpdateNote({ payload: { customerId, noteId, note } }) {
  try {
    const response = yield call(updateNoteApi, customerId, noteId, note);
    console.log("saga Update", response);
    yield put(updateNotesSuccess(noteId, note));
    showToast("Note Updated Successfully!", "SUCCESS");
  } catch (error) {
    yield put(updateNotesFail(error));
    showToast(`Note failed to Update ${error}`, "Error Updating", "error");
  }
}

function* onDeleteNote({ payload: { customerId, noteId } }) {
  try {
    const response = yield call(deleteNoteApi, customerId, noteId);
    yield put(deleteNoteSuccess(noteId));
    showToast("Note Deleted Successfully!", "Deleted");
  } catch (error) {
    yield put(deleteNoteFail(error));
    showToast(`Note failed to delete ${error}`, "Error deleting", "error");
  }
}

function* fetchCustomerInvoice() {
  try {
    const response = yield call(customerInvoice);
    yield put(getCustomerInvoiceSuccess(response));
  } catch (error) {
    yield put(getCustomerInvoiceFail(error));
  }
}

function* onDeleteAllInvoice({ payload: customerId }) {
  try {
    const response = yield call(deleteAllInvoiceApi, customerId);
    yield put(deleteAllInvoiceSuccess());
    showToast("All Invoice Deleted Successfully!", "Deleted !");
  } catch (error) {
    yield put(deleteAllInvoiceFail(error));
    showToast(`Invoice failed to Delete ${error}`, "Error Deleting", "error");
  }
}

function* onDeleteInvoice({ payload: { customerId, invoiceId } }) {
  try {
    const response = yield call(deleteInvoiceApi, customerId, invoiceId);
    yield put(deleteInvoiceSuccess(invoiceId));
    showToast("Invoice Deleted Successfully!", "Deleted !");
  } catch (error) {
    yield put(deleteInvoiceFail(error));
    showToast(`Invoice failed to Delete ${error}`, "Error Deleting", "error");
  }
}

function* fetchTask({ payload: customerId }) {
  try {
    const response = yield call(onGetTask, customerId);
    yield put(getTasksSuccess(response));
  } catch (error) {
    yield put(getTasksFail(error));
  }
}

function* onAddTask({ payload: { customerId, task } }) {
  try {
    const response = yield call(onAddTaskApi, customerId, task);
    yield put(addTaskSuccess(response));
    showToast("Task Added Successfully!", " Success");
  } catch (error) {
    yield put(addTaskFail(error));
    showToast(`Task failed to Update ${error}`, "Error updating", "error");
  }
}

function* onDeleteTask({ payload: { customerId, taskId } }) {
  try {
    const response = yield call(deleteTaskApi, customerId, taskId);
    yield put(deleteTaskSuccess(taskId));
    showToast("Task Deleted Successfully!", "Deleted !");
  } catch (error) {
    yield put(deleteTaskFail(error));
    showToast(`Task failed to Delete ${error}`, "Error Deleting", "error");
  }
}

function* onUpdateTask({ payload: { customerId, taskId, task, status } }) {
  try {
    const response = yield call(
      updateTaskApi,
      customerId,
      taskId,
      task,
      status
    );
    yield put(updateTaskSuccess(taskId, task, status));
    showToast("Task Updated Successfully!", "SUCCESS");
  } catch (error) {
    yield put(updateTaskFail(error));
    showToast(`Task failed to Update ${error}`, "Error Updating", "error");
  }
}

function* domainAvailability({ payload: siteName }) {
  try {
    const response = yield call(domainAvailabilityApi, siteName);
    yield put(domainAvailabilitySuccess(response));
  } catch (error) {
    yield put(domainAvailabilityFail(error));
  }
}

function* domainSuggestion({ payload: siteName }) {
  try {
    const response = yield call(domainSuggestionApi, siteName);
    yield put(domainSuggestionSuccess(response));
  } catch (error) {
    yield put(domainSuggestionFail(error));
  }
}

function* onbuyDomain({ payload: domainName }) {
  try {
    const response = yield call(buyDomainApi, domainName);
    yield put(buyDomainSuccess(response));
  } catch (error) {
    yield put(buyDomainFail(error));
  }
}

function* ecommerceSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts);
  yield takeEvery(GET_PRODUCT_DETAIL, fetchProductDetail);
  yield takeEvery(GET_PRODUCT_LIST, fetchProductList);
  yield takeEvery(UPDATE_PRODUCT_IN_LIST, onUpdateProductInList);
  yield takeEvery(DELETE_PRODUCT_IN_LIST, onDeleteProductInList);
  yield takeEvery(ADD_NEW_PRODUCT_IN_LIST, onAddNewProductInList);
  yield takeEvery(GET_ORDERS, fetchOrders);
  yield takeEvery(GET_CART_DATA, fetchCartData);
  yield takeEvery(GET_CUSTOMERS, fetchCustomers);
  yield takeEvery(ADD_NEW_CUSTOMER, onAddNewCustomer);
  yield takeEvery(UPDATE_CUSTOMER, onUpdateCustomer);
  yield takeEvery(DELETE_CUSTOMER, onDeleteCustomer);
  yield takeEvery(DELETE_ALL_CUSTOMERS, onDeleteAllCustomers);
  yield takeEvery(IMPORT_CUSTOMERS, onImportCustomers);
  yield takeEvery(GET_SHOPS, fetchShops);
  yield takeEvery(ADD_NEW_ORDER, onAddNewOrder);
  yield takeEvery(UPDATE_ORDER, onUpdateOrder);
  yield takeEvery(DELETE_ORDER, onDeleteOrder);
  yield takeEvery(DELETE_ALL_ORDERS, onDeleteAllOrders);
  yield takeEvery(GET_PRODUCT_COMMENTS, getProductComents);
  yield takeEvery(ON_LIKE_COMMENT, onLikeComment);
  yield takeEvery(ON_LIKE_REPLY, onLikeReply);
  yield takeEvery(ON_ADD_REPLY, onAddReply);
  yield takeEvery(ON_ADD_COMMENT, onAddComment);
  yield takeEvery(GET_INVENTORY, fetchInventory);
  yield takeEvery(UPDATE_INVENTORY, onUpdateInventory);
  yield takeEvery(GET_CUSTOMER_ORDER, fetchCustomerOrder);
  yield takeEvery(GET_NOTES, fetchNotes);
  yield takeEvery(ADD_NOTES, onAddNotes);
  yield takeEvery(DELETE_ALL_NOTES, onDeleteAllNotes);
  yield takeEvery(UPDATE_NOTES, onUpdateNote);
  yield takeEvery(DELETE_NOTE, onDeleteNote);
  yield takeEvery(GET_CUSTOMER_INVOICE, fetchCustomerInvoice);
  yield takeEvery(DELETE_ALL_INVOICE, onDeleteAllInvoice);
  yield takeEvery(DELETE_INVOICE, onDeleteInvoice);
  yield takeEvery(GET_TASK, fetchTask);
  yield takeEvery(ADD_TASK, onAddTask);
  yield takeEvery(DELETE_TASK, onDeleteTask);
  yield takeEvery(UPDATE_TASK, onUpdateTask);
  yield takeEvery(ADD_COLLECTION, onAddCollection);
  yield takeEvery(GET_COLLECTIONS, fetchCollections);
  yield takeEvery(UPDATE_COLLECTION, onUpdateCollection);
  yield takeEvery(DELETE_COLLECTION, onDeleteCollection);
  yield takeEvery(DOMAIN_AVAILABILITY, domainAvailability);
  yield takeEvery(DOMAIN_SUGGESTION, domainSuggestion);
  yield takeEvery(BUY_DOMAIN, onbuyDomain);
}

export default ecommerceSaga;
