import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { MetaTags } from "react-meta-tags";

import { buyDomain } from "store/actions";

import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Badge,
  Input,
  Label,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Table,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";

const EcommerceBuyDomain = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { domain } = useSelector(state => ({
    domain: state.ecommerce.domain,
  }));

  console.log(domain);

  const { siteData } = location.state;

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Buy Domain | Scrollit</title>
        </MetaTags>
        <Container fluid={true}>
          <Row>
            <h1 className="mb-4" style={{ fontWeight: "600" }}>
              You’re almost there! Complete your order
            </h1>

            {/* 1. Choose Period */}
            <h1 className="mt-4 mb-4">1. Choose a period</h1>
            <Card>
              <CardBody>
                <div className="d-flex align-item">
                  <Col>
                    <p
                      className="font-size-20 mb-0 "
                      style={{ fontWeight: 600 }}
                    >
                      <i
                        className="bx bx-check-shield me-2 "
                        id="privacyIcon"
                      />
                      <UncontrolledTooltip
                        placement="bottom"
                        target="privacyIcon"
                      >
                        Domain privacy protection hides your personal
                        information.It helps to avoid spam and keeps your data
                        safe from the public.
                      </UncontrolledTooltip>
                      {siteData?.domain}
                    </p>
                  </Col>
                  <Col className="d-flex align-item justify-space-between ">
                    <Col xs={5} style={{ position: "relative" }}>
                      <span className="domain-period">Period</span>
                      <label
                        className="visually-hidden"
                        htmlFor="inlineFormSelectPref"
                      >
                        Period
                      </label>
                      <select
                        defaultValue="0"
                        className="form-select font-size-16"
                      >
                        <option value="0">1 year</option>
                      </select>
                    </Col>
                    <Badge
                      color="success"
                      pill={true}
                      className="font-size-16 "
                      style={{ padding: "9px 22px" }}
                    >
                      Save 25%
                    </Badge>
                    <div style={{ textAlign: "end" }}>
                      <p style={{ margin: "0" }}>
                        <del>999.0</del>
                      </p>
                      <p
                        style={{
                          margin: "0",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                      >
                        Rs 735.00/yr
                      </p>
                    </div>
                  </Col>
                </div>
              </CardBody>
            </Card>

            {/* 2. Select Payment */}
            <h1 className="mt-4 mb-4">2. Select payment</h1>
            <Card>
              <CardBody>
                <div>
                  <CardTitle>Payment information</CardTitle>
                  <CardSubtitle className="mb-4">
                    Fill all information below
                  </CardSubtitle>
                  <div>
                    <div className="form-check form-check-inline font-size-16">
                      <Input
                        type="radio"
                        value="1"
                        id="customRadioInline1"
                        name="customRadioInline1"
                        className="form-check-input"
                      />
                      <Label
                        className="form-check-label font-size-13"
                        htmlFor="customRadioInline1"
                      >
                        <i className="fab fa-cc-mastercard me-1 font-size-20 align-top" />{" "}
                        Credit / Debit Card
                      </Label>
                    </div>
                    <div className="form-check form-check-inline font-size-16">
                      <Input
                        type="radio"
                        value="2"
                        id="customRadioInline2"
                        name="customRadioInline1"
                        className="form-check-input"
                      />
                      <Label
                        className="form-check-label font-size-13"
                        htmlFor="customRadioInline2"
                      >
                        <i className="fab fa-cc-paypal me-1 font-size-20 align-top" />{" "}
                        Paypal
                      </Label>
                    </div>
                    <div className="form-check form-check-inline font-size-16">
                      <Input
                        type="radio"
                        value="3"
                        id="customRadioInline3"
                        defaultChecked
                        name="customRadioInline1"
                        className="form-check-input"
                      />
                      <Label
                        className="form-check-label font-size-13"
                        htmlFor="customRadioInline3"
                      >
                        <i className="far fa-money-bill-alt me-1 font-size-20 align-top" />{" "}
                        Cash on Delivery
                      </Label>
                    </div>
                  </div>

                  <h5 className="mt-5 mb-3 font-size-15">For card Payment</h5>
                  <div className="p-4 border">
                    <Form>
                      <FormGroup className="mb-0">
                        <Label htmlFor="cardnumberInput">Card Number</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="cardnumberInput"
                          placeholder="0000 0000 0000 0000"
                        />
                      </FormGroup>
                      <Row>
                        <Col lg="6">
                          <FormGroup className="mt-4 mb-0">
                            <Label htmlFor="cardnameInput">Name on card</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="cardnameInput"
                              placeholder="Name on Card"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup className=" mt-4 mb-0">
                            <Label htmlFor="expirydateInput">Expiry date</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="expirydateInput"
                              placeholder="MM/YY"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="3">
                          <FormGroup className="mt-4 mb-0">
                            <Label htmlFor="cvvcodeInput">CVV Code</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="cvvcodeInput"
                              placeholder="Enter CVV Code"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/*3.  Confirmation */}
            <h1 className="mt-4 mb-4">3. Confirmation</h1>
            <Card>
              <CardBody>
                <div className="table-responsive">
                  <Table className="table-nowrap">
                    <thead>
                      <tr>
                        <th>Site</th>
                        <th className="text-end">Price</th>
                        <th className="text-end">Duration</th>
                        <th className="text-end">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Qwerty.com</td>
                        <td className="text-end">750</td>
                        <td className="text-end">1</td>
                        <td className="text-end">{750 * 1}</td>
                      </tr>

                      <tr>
                        <td colSpan="3" className="text-end">
                          Sub Total
                        </td>
                        <td className="text-end">735</td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="border-0 text-end">
                          <strong>Shipping</strong>
                        </td>
                        <td className="border-0 text-end">0</td>
                      </tr>
                      <tr>
                        <td colSpan="3" className="border-0 text-end">
                          <strong>Total</strong>
                        </td>
                        <td className="border-0 text-end">
                          <h4 className="m-0">₹ 730</h4>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <div style={{ textAlign: "end" }}>
                  <Link
                    to={`/ecomm-Domain-paymentStatus/demo`}
                    onClick={e => dispatch(buyDomain(siteData?.domain))}
                  >
                    <Button color="primary">
                      Proceed to Pay <i className="mdi mdi-arrow-right" />
                    </Button>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EcommerceBuyDomain;
